import TruckInfo from "../components/TruckInfo";
import { getLastGpsPositionVehicle } from "../api/GpsApi";
import { FaMapMarkerAlt } from 'react-icons/fa';
import React, { useState, useEffect, useRef } from "react";
import { TextField, Grid, Typography, Box } from '@mui/material';
import { updateTruck } from "../api/TrucksApi";
import TripTooltip from "../components/TripTooltip";
import { CirclePicker } from 'react-color';
import "../styles/TrucksTable.css"
import { extractTemperature, getTemperatureColor } from "../utils/utils";

const TruckRow = ({ item, isEditing, setIsEditing }) => {

    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const [gpsLocation, setGpsLocation] = useState('');
    const [gpsError, setGpsError] = useState(null);

    const [localItem, setLocalItem] = useState(item.truck);
    const timeoutRef = useRef(null);

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const handleColorChange = (field) => (color) => {
        clearTimeout(timeoutRef.current);
        const newItem = { ...localItem, [field]: color.hex };
        setLocalItem(newItem);
        setIsEditing(true);
        setDisplayColorPicker(false);
        timeoutRef.current = setTimeout(() => {
            updateTruck(newItem);
            setIsEditing(false);
        }, 2000); // Ожидание 2 секунды после окончания ввода
    };

    const handleInputChange = (field) => (e) => {
        clearTimeout(timeoutRef.current);
        const newItem = { ...localItem, [field]: e.target.value };
        setLocalItem(newItem);
        setIsEditing(true);
        timeoutRef.current = setTimeout(() => {
            updateTruck(newItem);
            setIsEditing(false);
        }, 2000); // Ожидание 2 секунды после окончания ввода
    };

    useEffect(() => {
        if (!isEditing) {
            setLocalItem(item.truck);
        }
    }, [item]);

    return (
        <Grid container >
            <Grid item sm={1} className='row-cell'><TruckInfo item={item} /></Grid>
            <Grid item sm={2} className='row-cell'>{item.last_complited_route && (
                <TripTooltip
                    key={item.last_complited_route._id}
                    trip={item.last_complited_route}
                    selectedTruck={item.last_complited_route.truck}
                />)}
            </Grid>
            {[...item.routes]
                .sort((a, b) =>
                    new Date(a.load_date) - new Date(b.load_date)).slice(0, 2)
                .map((route) => (
                    <Grid item sm={2} className='row-cell' key={route._id}>
                        <TripTooltip trip={route} selectedTruck={route.truck} />
                    </Grid>
                ))}
            {item.routes.length === 0 &&
                <>
                    <Grid item sm={2} className='row-cell' key="empty1"></Grid>
                    <Grid item sm={2} className='row-cell' key="empty2"></Grid>
                </>
            }
            {item.routes.length === 1 &&
                <Grid item sm={2} className='row-cell' key="empty3"></Grid>
            }
            <Grid
                item
                container
                sm={1}
                className='row-cell'
                overflow={'hidden'}
                maxHeight={'56px'}
                onClick={async () => {
                    try {
                        setGpsLocation(await getLastGpsPositionVehicle(item.truck, item.last_route.trailer));
                        setGpsError(null); // Очистити попередню помилку, якщо вона була
                    } catch (error) {
                        setGpsError(error.message); // Зберегти помилку у стані
                    }
                }}
                style={{ fontSize: '11px' }}
                key="gpsLocation"
                title={gpsLocation ?
                    gpsLocation.date + (gpsLocation.temperature ? " | " + gpsLocation.temperature : '') + "\n"
                    + (gpsLocation.displayAddress ? gpsLocation.displayAddress.replaceAll(',', '\n') : '')
                    : (item.truck.gpsId || item.last_route.trailer.gpsId ? 'Отримати локацію'
                        : 'Отримати локації по чекпоінтам')}
            >
                {gpsLocation ? (
                    <>
                        <Grid item container justifyContent={'space-between'}>
                            <Grid item>
                                <Typography
                                    fontSize={10}
                                    fontFamily={'Roboto'}
                                    variant="caption"
                                    color="grey"
                                >
                                    {gpsLocation ? gpsLocation.date : ''}
                                </Typography>

                            </Grid>
                            <Grid item>
                                <Typography fontWeight={600} fontSize={10} style={{ color: getTemperatureColor(gpsLocation ? gpsLocation.temperature : 's') }}>
                                    {gpsLocation && gpsLocation.temperature ? extractTemperature(gpsLocation.temperature) + "°" : ''}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography fontSize={10} fontFamily={'Roboto'} style={{ lineHeight: 0.9 }}>
                                {gpsLocation ? gpsLocation.displayAddress : ''}
                            </Typography>
                        </Grid>
                    </>
                ) : (
                    <>
                        {item.truck.gpsId || item.last_route.trailer.gpsId ? (
                            <Box pl={1} alignSelf={'center'} textAlign={'center'}>
                                <FaMapMarkerAlt style={{ paddingRight: '1px' }} />
                                <Typography variant="caption" color={'blue'}>Отримати локацію</Typography>
                                {gpsError ? (<Box pl={1}><Typography variant="caption" color={'red'}>Помилка GPS</Typography></Box>) : ("")}
                            </Box>
                        ) : (
                            <Box pl={1} alignSelf={'center'}>
                                <FaMapMarkerAlt style={{ paddingRight: '1px' }} />
                                <Typography variant="caption">
                                    GPS по відміткам
                                </Typography>
                            </Box>
                        )}
                    </>
                )}
            </Grid>

            <Grid item sm={2} className='row-cell' key="logistics_comment">
                <TextField className="comment"
                    multiline
                    maxRows={3}
                    InputProps={{
                        style: {
                            fontSize: "11px",
                            padding: "2px",
                        },
                        sx: { height: '100%' }
                    }}
                    fullWidth
                    variant="standard"
                    type="textarea"
                    value={localItem.logistics_comment}
                    onChange={handleInputChange('logistics_comment')} />
            </Grid>
            <Grid item sm={1} className='row-cell' key="tech_comment">
                <TextField className="comment"
                    multiline
                    maxRows={3}
                    InputProps={{
                        style: {
                            fontSize: "11px",
                            padding: "2px"
                        },
                        sx: { height: '100%' }
                    }}
                    fullWidth
                    variant="standard"
                    type="textarea"
                    value={localItem.tech_comment}
                    onChange={handleInputChange('tech_comment')} />
            </Grid>
            <Grid item sm={1} className='row-cell' key="hr_comment"
                style={{ position: 'relative' }}>
                {displayColorPicker ?
                    <div style={{
                        width: '125px',
                        backgroundColor: '#e996a6',
                        borderRadius: '5px',
                        position: 'absolute',
                        left: "-150px",
                        top: '20px',
                        zIndex: '2',
                        border: '1px solid black'
                    }}>
                        <div style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px'
                        }}
                            onClick={handleClose} />
                        <CirclePicker
                            circleSize={20}
                            colors={['red', '#37FF2C', 'yellow', 'transparent']}
                            color={localItem.hr_comment_color}
                            onChange={handleColorChange('hr_comment_color')} />
                    </div>
                    : null
                }
                <TextField className="comment"
                    multiline
                    maxRows={3}
                    InputProps={{
                        style: {
                            backgroundColor: localItem.hr_comment_color,
                            fontSize: "11px",
                            padding: "2px"
                        },
                        sx: { height: '100%' }
                    }}
                    fullWidth
                    variant="standard"
                    type="textarea"
                    value={localItem.hr_comment}
                    onChange={handleInputChange('hr_comment')}
                    onFocus={handleClick} />
            </Grid>
        </Grid>
    );
}

export default TruckRow;