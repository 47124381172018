import React, { useState } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { formatDateWithoutYear } from '../utils/dateUtils';
import { FaRegCalendarTimes } from 'react-icons/fa';
import { findCheckpointColor } from "../utils/checkpointsUtils";
import "../styles/TripDetails.css"
import TripDetails from './TripDetails';
import { AddCheckpointDialog } from './AddCheckpoint';

function TripTooltip({ trip }) {

  const [checkpDialogOpen, setCheckpDialogOpen] = useState(false);
  const toggleDialog = (event) => {
    event.stopPropagation();
    setCheckpDialogOpen(!checkpDialogOpen);
  };


  
  return (
    <Box className='info-block'>
      {trip._id && (
        <Box key={trip._id}
          style={{
            height: '100%',
            backgroundColor: findCheckpointColor(trip.checkpoints[trip.checkpoints.length - 1].name)
          }}>
          <Tooltip
            placement="right-start"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  border: "solid grey 1px",
                  backgroundColor: "#F2F5FD",
                  color: 'black',
                  maxWidth: "600px"
                }
              },
              disablePortal: true,
              popperOptions: {
                positionFixed: true,
                modifiers: {
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: "window" // where "window" is the boundary
                  }
                }
              }
            }}
            title={
              <TripDetails
                selectedTrip={trip}
                selectedTruck={trip.truck}
                showCheckpoints={true} />
            }

          >
            <div className='status'>
              <Typography
                variant='caption'
                style={{ fontWeight: '500' }}
                onClick={toggleDialog}
                >
                {trip.checkpoints[trip.checkpoints.length - 1].name}
              </Typography>
              <div style={{ paddingRight: '5px', paddingTop: '3px' }}>
                {trip.checkpoints[trip.checkpoints.length - 1].name !== 'Рейс завершено' &&
                  ((new Date(trip.unload_date)) < (new Date())) ?
                  <FaRegCalendarTimes size={14} /> : <></>}
              </div>
            </div>
          </Tooltip>
          <AddCheckpointDialog
            onAddCheckpoint={()=> {}}
            open={checkpDialogOpen}
            trip={trip}
            toggleDialog={toggleDialog}
          />
          <div className='trip-card-point' >
            <Typography fontFamily={'Roboto'} fontSize={12}>
              {trip.point_load.city ? trip.point_load.city : trip.point_load.region}
            </Typography>
            <small>{formatDateWithoutYear(trip.load_date)}</small>
          </div>
          <div className='trip-card-point' >
            <Typography fontFamily={'Roboto'} fontSize={12}>
              {trip.point_unload.city ? trip.point_unload.city : trip.point_unload.region}
            </Typography>
            <small>{formatDateWithoutYear(trip.unload_date)}</small>
          </div>
        </Box>)
      }
    </Box>
  );
}

export default TripTooltip;
