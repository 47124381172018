import { serverUrl } from "../config";
import axios from "axios";

const addCheckpoint = async (trip, checkpoint) => {
  // Выполняем POST запрос на сервер, передавая id рейса и объект чекпоинта
  const token = localStorage.getItem('token');
  checkpoint = { name: checkpoint.name, date: new Date() }
  try {
    const response = await axios.post(`${serverUrl}/api/routes/${trip._id}/checkpoints`, checkpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }

}

const fetchClientTrips = async (page, limit) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(`${serverUrl}/api/routes/client/paginatedAll?page=${page}&limit=${limit}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { totalPages: response.data.totalPages, trips: response.data.trips };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export { addCheckpoint, fetchClientTrips }

